export const categorysFunc: any = (str?: string) => {
  return {
    'Region': '地域',
    'OrderCategory': '订单类别',
    'Cost': '成本',
  }
}



